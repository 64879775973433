import React, { Component } from "react";
// import { Modal, Button, Row, Col, Dropdown } from 'react-bootstrap';
import Modal from "react-responsive-modal";
import { withTranslation, Trans } from 'react-i18next';

class ModalConfirm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal
        open={this.props.show}
        onClose={this.props.onClose || this.props.onHide}
        showCloseIcon={false}
        center
        classNames={{
          overlay: { dialogOverlay: "white", "hight_z_index": this.props.hight_z_index ? "white" : "" },
          modal: { dialog: "white", dialogDeleteSchool: "white" }
        }}
      >
        <div className="delete-school">
          <h2>{ this.props.t(this.props.title || 'Are you sure ?') }</h2>
          <p>{this.props.message}</p>
          <div className="button-delete">
            <button
              className="no"
              onClick={() => this.props.onHide()}
            >
              { this.props.t('NO') }
            </button>
            <button
              className="yes"
              onClick={() => this.props.handleValidate()}
            >
              { this.props.t('YES') }
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
export default withTranslation('translations')(ModalConfirm);
