import React, { Component } from "react";
import Modal from "react-responsive-modal";

import purple from "@material-ui/core/colors/purple";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTranslation, Trans } from 'react-i18next';
import FormLabel from "@material-ui/core/FormLabel";
import { images } from "../_resources";
import TextInput from "../../../../components/TextInput";
import Button from "../../../../components/Button";
import { Formik, Form, Field } from "formik";
import { Link, withRouter } from "react-router-dom";
import * as Yup from "yup";

import Radio from "@material-ui/core/Radio";

import { RegisterAction, loginAction } from "../../../../services/actions";
import { globalData } from "services/cms/actions";
import ModalCompteSuccess from "./ModalCompteSuccess";
import ModalPassword from "./ModalPassword";

import { get } from "../../../../services/storage";
import ModalChoix from "./ModalChoix";
import RegisterFormComponent from 'components/RegisterFormComponent'
import ModalTermsAndCondition from "pages/professor/home/components/ModalTermsAndCondition";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: purple[500] }, // Purple and green play nicely together.
    secondary: { main: "#00b3a9" } // This is just green.A700 as hex.
  }
});

class ModalSignRegisterMobile extends Component {
  constructor(props){
    super(props);
  }
  state = {
    success: false,
    messageSuccess: "",
    selectedValue: "a",
    captcha: {
      min: Math.floor(Math.random() * 10),
      max: Math.floor(Math.random() * 10)
    },
    errorCaptcha: false,
    errorCaptchaSignIn: false,
    errorGlobal: "",
    errorGlobalSignIn: "",

    open: false,
    openChoix: false,
    passwordError: 0,
    captchaInput: "",
    openModalTerms: false
  };

  handleCloseModalCnxChoix = () => {
    this.setState({
      openChoix: false
    });
  };

  onOpenModalPassword = () => {
    this.props.handleCloseRegisterMobile();
    this.setState({
      open: true
    });
  };

  onCloseModalPassword = () => {
    this.setState({
      open: false
    });
  };

  handleChangeCaptcha(e) {
    this.setState({ captchaInput: e.target.value });
  }

  handleChangeCheck = event => {
    this.setState({ selectedValue: event.target.value });
  };

  handleOpenSuccess = msg => {
    this.setState({
      success: true,
      messageSuccess: msg
    });
  };

  handlePasswordReseted = message => {
    this.setState({
      success: true,
      messageSuccess: message,
      open: false
    });
  };

  handleCloseSuccess = () => {
    this.setState({
      success: false,
      messageSuccess: ""
    });
  };

  getValidationSchema = () => {
    return Yup.object().shape({
      firstName: Yup.string().required(this.props.t("First name required")),
      lastName: Yup.string().required(this.props.t("Last Name required")),
      id: Yup.string().required(this.props.t("ID required")),
      password: Yup.string()
        // .min(5, this.props.t("Password must be greater than 5 character"))
        .required(this.props.t("Password required")),
      email: Yup.string()
        .email(this.props.t("Email format incorrect"))
        .required(this.props.t("Email required")),
      captcha: Yup.string().required(this.props.t("Captcha required"))
    });
  };

  getValidationSchemaSignIn = () => {
    return Yup.object().shape({
      id: Yup.string().required(this.props.t("Username required")),
      password: Yup.string().required(this.props.t("Password required"))
    });
  };

  handleSubmit = form => {
    if (form.captcha == this.state.captcha.min + this.state.captcha.max) {
      let register;
      register = {
        username: form.id,
        password: form.password,
        first_name: form.firstName,
        last_name: form.lastName,
        mail_or_phone: form.test,
        parent_email: form.email,
        parent_phone_number: null
      };
      return this.props
        .dispatch(RegisterAction(register))
        .then(data => {
          if (data.payload.success) {
            this.props.handleCloseRegisterMobile();
            setTimeout(() => {
              this.handleOpenSuccess();
            }, 1000);
          }
        })
        .catch(err => {
          this.setState({
            captcha: {
              min: Math.floor(Math.random() * 10),
              max: Math.floor(Math.random() * 10)
            },
            errorGlobal: err.message
          });
        });
    } else {
      this.setState({
        errorCaptcha: true,
        captcha: {
          min: Math.floor(Math.random() * 10),
          max: Math.floor(Math.random() * 10)
        }
      });
    }
  };

  handleSubmitSignIn = form => {
    if (this.state.passwordError <= 3) {
      let login;
      login = {
        username: form.id,
        password: form.password
      };
      return this.props
        .dispatch(loginAction(login))
        .then(data => {
          if (data.payload.success) {
            if (data.payload.user.choose_account) {
              this.onCloseModal();
              this.setState({
                openChoix: true
              });
            } else {
              const user = get('session_user');
              if(user.profile.is_school_admin){
                this.props.history.push("/school/list");
              }
              else if(user.is_escort){
                this.props.history.push("/escort/transport");
              }
              else if(['pr','pr_pa'].indexOf(user.account_type) >= 0 && ((user.schools || user.profile && user.profile.schools || []).length > 0 && get('professor_subjects').length > 0 || user.profile && user.profile.had_sanady_school_from_last_year)){
                
                if(false && !((user.schools || user.profile && user.profile.schools || []).length > 0 && get('professor_subjects').length > 0)){
                  this.props.history.push(`/professor/info_profile`);
                }
                else{
                  this.props.dispatch(globalData({}))
                  this.props.history.push(`/professor/classes`);
                }
              }
              else if(['pa', 'pr_pa'].indexOf(user.account_type) >= 0)
                this.props.history.push(`/parents/dashboard`);
              else if(['ch'].indexOf(user.account_type) >= 0){
                if (data.payload.user.first_login) {
                  this.props.history.push({
                    pathname: "/child/profile",
                    search: "?login=first_login"
                  });
                } else {
                  this.props.history.push("/child/dashboard");
                }
              }
            }

            // if (data.payload.user.choose_account) {
            //   set("loginChoix", login);
            //   this.onCloseModal();
            //   this.setState({
            //     openChoix: true
            //   });
            // } else {
            //   if (data.payload.user.first_login) {
            //     this.props.history.push({
            //       pathname: "/profile",
            //       search: "?login=first_login"
            //     });
            //   } else {
            //     this.props.history.push("/dashboard");
            //   }
            // }
          }
        })
        .catch(err => {
          console.log("err", err);
          this.setState(state => ({
            passwordError: state.passwordError + 1,
            errorGlobalSignIn: err.message
          }));
        });
    } else {
      if (
        this.state.captchaInput ==
        this.state.captcha.min + this.state.captcha.max
      ) {
        let login;
        login = {
          username: form.id,
          password: form.password
        };
        return this.props
          .dispatch(loginAction(login))
          .then(data => {
            if (data.payload.success) {
              if (data.payload.user.choose_account) {
                this.onCloseModal();
                this.setState({
                  openChoix: true
                });
              }else{
                const user = get('session_user');
                if(user.profile.is_school_admin){
                  this.props.history.push("/school/list");
                }
                else if(user.is_escort){
                  this.props.history.push("/escort/transport");
                }
                else if(['pr','pr_pa'].indexOf(user.account_type) >= 0 && ((user.schools || user.profile && user.profile.schools || []).length > 0 && get('professor_subjects').length > 0 || user.profile && user.profile.had_sanady_school_from_last_year)){
                  
                  if(false && !((user.schools || user.profile && user.profile.schools || []).length > 0 && get('professor_subjects').length > 0)){
                    this.props.history.push(`/professor/info_profile`);
                  }
                  else{
                    this.props.dispatch(globalData({}))
                    this.props.history.push(`/professor/classes`);
                  }
                }
                else if(['pa', 'pr_pa'].indexOf(user.account_type) >= 0)
                  this.props.history.push(`/parents/dashboard`);
                else if(['ch'].indexOf(user.account_type) >= 0){
                  if (data.payload.user.first_login) {
                    this.props.history.push({
                      pathname: "/child/profile",
                      search: "?login=first_login"
                    });
                  } else {
                    this.props.history.push("/child/dashboard");
                  }
                }
              }
                
            }
          })
          .catch(err => {
            console.log("err", err);
            this.setState(state => ({
              passwordError: state.passwordError + 1,
              errorGlobalSignIn: err.message
            }));
          });
      } else {
        this.setState({
          captcha: {
            min: Math.floor(Math.random() * 10),
            max: Math.floor(Math.random() * 10)
          },
          errorCaptchaSignIn: true
        });
      }
    }
  };

  onCloseModal = () => {
    this.handleCloseRegisterMobile();
  };

  handleCloseRegisterMobile = () => {
    this.props.handleCloseRegisterMobile();
    this.setState({
      errorCaptcha: false,
      errorCaptchaSignIn: false,
      errorGlobal: "",
      errorGlobalSignIn: "",
      passwordError: 0
    });
  };

  render() {
    return (
      <>
        <Modal
          open={!!this.props.popupSignin && !!this.props.popupSignin.popup}
          onClose={() => this.handleCloseRegisterMobile()}
          showCloseIcon={false}
          center
          classNames={{
            overlay: { dialogOverlay: "white", dialogMobile: "white" },
            modal: { dialog: "white", dialogChildren: "white" ,dialogConnexion: "white" }
          }}
        >
          <div
            className="closeRegister"
            onClick={() => this.handleCloseRegisterMobile()}
          >
            <span>x</span>
          </div>
          <div className="dialog-top">
            <a
              className={
                this.props.popupSignin &&
                this.props.popupSignin.signin &&
                "active"
              }
              onClick={() => this.props.handleOpenSigninMobile()}
            >
              {this.props.t(' CONNECTION')}
            </a>
            <a
              onClick={() => this.props.handleOpenRegisterMobile()}
              className={
                this.props.popupSignin &&
                !this.props.popupSignin.signin &&
                "active"
              }
            >
              {this.props.t('register')}
            </a>
          </div>
          {this.props.account_activated && (
            <div className="account_activated">
              Bienvenue sur Koolskools. Votre compte a été activé, vous pouvez
              maintenant accéder à votre espace en saisissant votre mot de
              passe.
            </div>
          )}
          {this.props.popupSignin && this.props.popupSignin.signin ? (
            <div className="dialog-content">
              <div className="dialog-left">
                <h3>{this.props.t('LEARNING BECOMES GREAT')} </h3>
                {/* <h3>APPRENDRE DEVIENT GENIAL</h3> */}
                <Formik
                  initialValues={{
                    id: this.props.username || "",
                    password: ""
                  }}
                  validationSchema={this.getValidationSchemaSignIn}
                  onSubmit={values => {
                    this.handleSubmitSignIn(values);
                  }}
                >
                  {({ errors, touched, values, handleChange }) => {
                    return (
                      <Form className="dialog-form">
                        {this.state.errorGlobalSignIn && (
                          <div className="textErrors">
                            {this.state.errorGlobalSignIn}
                          </div>
                        )}
                        <TextInput
                          className={`textInput ${errors.id &&
                            "textInputError"}`}
                          name="id"
                          label="Pseudo"
                          value={values.id}
                          onChange={handleChange}
                        />
                        {errors.id && touched.id ? (
                          <div className="textErrors">{errors.id}</div>
                        ) : null}
                        <TextInput
                          className={`textInput ${errors.password &&
                            "textInputError"}`}
                          type="password"
                          name="password"
                          label="MOT DE PASSE"
                          value={values.password}
                          onChange={handleChange}
                        />
                        {errors.password && touched.password ? (
                          <div className="textErrors">{errors.password}</div>
                        ) : null}

                        {this.state.passwordError >= 3 && (
                          <div className="captcha">
                            <p>
                              {this.state.captcha.min} +{" "}
                              {this.state.captcha.max} ={" "}
                            </p>
                            <input
                              name="captchaInput"
                              type="text"
                              value={this.state.captchaInput}
                              onChange={this.handleChangeCaptcha.bind(this)}
                              className="captchaInput"
                            />
                          </div>
                        )}

                        {this.state.errorCaptchaSignIn && (
                          <div className="textErrors">{this.props.t(' Captcha Invalid')}</div>
                        )}
                        {errors.captchaa && touched.captchaa ? (
                          <div className="textErrors">{errors.captchaa}</div>
                        ) : null}

                        <Button>{this.props.t('SIGN IN')}</Button>
                      </Form>
                    );
                  }}
                </Formik>
                {/*
                <p className="password-forgot">
                  <a onClick={() => this.onOpenModalPassword()}>
                  {this.props.t('Forgot your password')} ?
                  </a>{" "}
                </p>
                */}
              </div>

              <div className="dialog-right">
                <span>
                  <img src={images.imgSignin} className="img-fluid" />
                </span>
              </div>
            </div>
          ) : (
            <div className="dialog-content">
              <div className="">
                <h3> {this.props.t('LEARNING BECOMES GREAT')} </h3>
                <RegisterFormComponent 
                  pages="children"
                  handleOpenSuccess={(msg) => {
                    this.props.handleCloseRegisterMobile();
                    setTimeout(() => {
                      this.handleOpenSuccess(msg);
                    }, 1000);
                  }}
                  handleOpenTermsAndCondition={() => {
                    this.setState({openModalTerms:true})
                  }}
                  />
              </div>
            </div>
          )}
        </Modal>
        {this.state.open && (
          <ModalPassword
            open={this.state.open}
            handlePasswordReseted={message =>
              this.handlePasswordReseted(message)
            }
            onCloseModalPassword={this.onCloseModalPassword}
          />
        )}
        <ModalCompteSuccess
          success={this.state.success}
          message={this.state.messageSuccess}
          handleCloseSuccess={this.handleCloseSuccess}
        />
        {this.state.openModalTerms &&
        <ModalTermsAndCondition
          success={this.state.openModalTerms}
          handleClose={() => this.setState({openModalTerms:false})}
        />
        }
        <ModalChoix
          openChoix={this.state.openChoix}
          handleCloseModalCnxChoix={this.handleCloseModalCnxChoix}
          onCloseModal={this.onCloseModal}
        />
      </>
    );
  }
}

const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withRouter(withTranslation('translations')(ModalSignRegisterMobile)));
