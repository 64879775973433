import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import Button from "../../../../components/Button";
import TextInput from "../../../../components/TextInput";
import { withTranslation, Trans } from 'react-i18next';
import { forgotPasswordAction } from "../../../../services/actions";

class ModalPassword extends Component {
  constructor(props){
    super(props);
  }
  state = {
    text: "",
    error: false,
    response_message: ""
  };

  handleChange = e => {
    this.setState({ text: e.target.value });
  };

  handleForgotPassword = () => {
    if (this.state.text === "") {
      this.setState({
        error:  this.props.t("field is empty thank you to enter your username or your email")
        // error:"Le champ est vide merci de saisir votre username ou votre email"

      });
    } else {
      let obj = {
        username_or_email_or_phone: this.state.text,
        from: 'koolskools'
      };
      if(global.is_laraki_logo)
        obj.from = 'elaraki';
      else if(global.is_sanady_logo)
        obj.from = 'sanady';
      else if(global.is_pont_logo)
        obj.from = 'pont';
      else if(global.is_reference_logo)
        obj.from = 'reference';
      else if(global.is_yakada_logo)
        obj.from = 'yakada';
      else if(global.is_kastalani_logo)
        obj.from = 'kastalani';
      else if(global.is_babylone_logo)
        obj.from = 'babylone';
      else if(global.is_fmps_logo)
        obj.from = 'FMPS';
      return this.props
        .dispatch(forgotPasswordAction(obj))
        .then(data => {
          this.props.handlePasswordReseted(data.payload.response_msg);
        })
        .catch(error => {
          this.setState({
            error: error.response.data.error
          });
        });
    }
  };
  render() {
    /* TODO: TRANSLATIONS */

    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onCloseModalPassword}
        showCloseIcon={false}
        center
        classNames={{
          overlay: { dialogOverlay: "white", dialogMobile: "white" },
          modal: !global.is_koolskools?
          {
            dialog: "white",
            dialogSignIn: "white",
            dialogOubliePassword: "white",
            dialogLaraki: global.is_laraki_logo?"white":false,
            dialogSanady: global.is_sanady_logo?"white":false,
            dialogPont: global.is_pont_logo?"white":false,
            dialogFMPS: global.is_fmps_logo?"white":false
          }
          :
          {
            dialog: "white",
            dialogSignIn: "white",
            dialogOubliePassword: "white"
          }
        }}
      >
        <div
          className="closeRegister"
          onClick={() => this.props.onCloseModalPassword()}
        >
          <span>x</span>
        </div>

        <h3 style={{color: global.is_koolskools?'#3db1a9':global.colors[1]}}> { this.props.t('PASSWORD ASSISTANCE') }</h3>
        {this.props.pages ==="children" &&
          <p>
            Un pour réinitialiser votre mot de passe sera envoyé à votre parent.
          </p>
        }
        {/* {this.props.pages ==="professor" &&
          <p>
            Un message qui contiens votre mot de passe sera envoyé à votre email.
            Vous pouvez modifier votre mot de passe dans la page profile.
          </p>
        } */}
        {this.props.pages ==="school" &&
          <p>
            Un lien pour réinitialiser votre mot de passe sera envoyé à votre email.
          </p>
        }
        <TextInput
          className={`textInput `}
          type="text"
          name="email"
          label="ENTER YOUR USERNAME or your email or your phone number"
          value={this.state.text}
          onChange={this.handleChange}
        />
        {this.state.error && (
          <div className="textErrors">{this.state.error}</div>
        )}
        {this.state.response_message && (
          <div className="response_message">{this.state.response_message}</div>
        )}
        <Button onPress={this.handleForgotPassword}>{ this.props.t('RESET MY PASSWORD') }</Button>
      </Modal>
    );
  }
}

export default connect()(withTranslation('translations')(ModalPassword));
