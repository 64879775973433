import React from "react";
import { Route, withRouter, Redirect } from "react-router-dom";
import LayoutChildSystem from "./components/LayoutChildSystem";
import LayoutParentSystem from "./components/LayoutParentSystem";
import LayoutSchoolSystem from "./components/LayoutSchoolSystem";
import LayoutPilotageSystem from "./components/LayoutPilotageSystem";
import LayoutEscortSystem from "./components/LayoutEscortSystem";
import LayoutUnconnectedSystem from "./components/LayoutUnconnectedSystem";
import { clear, set, get, remove } from "./services/storage";
import {get_all_levels_api} from 'services/cms/api'
import localforage from 'localforage';

const AppChildRoute = ({
  component: Component,
  layout: Layout,
  history,
  ...rest
}) => {
  var user = get("session_user");
  if(!user || ['ch'].indexOf(user.account_type) < 0) 
    return <Redirect to="/" />;
  if(/([À-ú]|\s)/.test(user.profile.username)){
    var lev = get("level_groups");
    var current_language = get("current_language");
    clear();
    localforage.clear();
    if(global.ws_notification)
      global.ws_notification.close();
    set("level_groups", lev);
    set("current_language", current_language);
    return <Redirect to="/" />;
  }
  var all_levels = get("all_levels") || [];
  if(all_levels.length == 0){
    get_all_levels_api();
  }
  return (
    <>
      <Route
        {...rest}
        render={props => (
          <LayoutChildSystem Component={Component} Layout={Layout}{...props} {...rest} />
        )}
      />
    </>
  );
};

// export default withRouter(AppRoute);

const AppParentRoute = ({
  component: Component,
  layout: Layout,
  history,
  ...rest
}) => {
  var user = get("session_user");
  if(!user || ['pa','pr_pa'].indexOf(user.account_type) < 0) 
    return <Redirect to="/home/parent" />;
  if(/([À-ú]|\s)/.test(user.profile.username)){
    var lev = get("level_groups");
    var current_language = get("current_language");
    clear();
    localforage.clear();
    if(global.ws_notification)
      global.ws_notification.close();
    set("level_groups", lev);
    set("current_language", current_language);
    return <Redirect to="/home/parent" />;
  }
  return (
    <>
      <Route
        {...rest}
        render={props => (
          <LayoutParentSystem Component={Component} Layout={Layout} {...props} {...rest}/>
        )}
      />
    </>
  );
};

const AppProfessorRoute = ({
  component: Component,
  layout: Layout,
  history,
  ...rest
}) => {
  var user = get("session_user");
  if(!user || ['pr_pa'].indexOf(user.account_type) < 0 && ((user.schools || user.profile && user.profile.schools || []).length > 0 || user.profile && user.profile.had_sanady_school_from_last_year))
    return <Redirect to="/home/professor" />;
  if(/([À-ú]|\s)/.test(user.profile.username)){
    var lev = get("level_groups");
    var current_language = get("current_language");
    clear();
    localforage.clear();
    if(global.ws_notification)
      global.ws_notification.close();
    set("level_groups", lev);
    set("current_language", current_language);
    return <Redirect to="/home/professor" />;
  }
  return (
    <>
      <Route
        {...rest}
        render={props => (
          <LayoutSchoolSystem Component={Component} Layout={Layout} school_account={false} {...props} {...rest}/>
        )}
      />
    </>
  );

  // return (
  //   <>
  //     <Route
  //       {...rest}
  //       render={props => (
  //         <Component {...props} />
  //       )}
  //     />
  //   </>
  // );
};


const AppSchoolRoute = ({
  component: Component,
  layout: Layout,
  history,
  pagetype,
  ...rest
}) => {
  var user = get("session_user");
  if(!user || ['pa', 'pr_pa', 'sc_ad', 'sc_as'].indexOf(user.account_type) < 0)
    return <Redirect to="/home/school" />;
  if(/([À-ú]|\s)/.test(user.profile.username)){
    var lev = get("level_groups");
    var current_language = get("current_language");
    clear();
    localforage.clear();
    if(global.ws_notification)
      global.ws_notification.close();
    set("level_groups", lev);
    set("current_language", current_language);
    return <Redirect to="/home/school" />;
  }
  
  if(Layout){
    return (
      <>
        <Route
          {...rest}
          render={props => (
            <LayoutSchoolSystem added_class={rest.added_class}  Component={Component} pagetype={pagetype} Layout={Layout} school_account={true} {...props} {...rest}/>
          )}
        />
      </>
    );
  }
  else{
    return (
      <>
        <Route
          {...rest}
          render={props => (
            <Component added_class={rest.added_class} {...props} />
          )}
        />
      </>
    );
  }
  
};
const AppPilotageRoute = ({
  component: Component,
  layout: Layout,
  history,
  ...rest
}) => {
  var user = get("session_user");
  if(!user || ['ceo', 'csm'].indexOf(user.account_type) < 0)
    return <Redirect to="/pilotage" />;
  if(/([À-ú]|\s)/.test(user.profile.username)){
    var lev = get("level_groups");
    var current_language = get("current_language");
    clear();
    localforage.clear();
    if(global.ws_notification)
      global.ws_notification.close();
    set("level_groups", lev);
    set("current_language", current_language);
    return <Redirect to="/pilotage" />;
  }
  
  if(Layout){
    return (
      <>
        <Route
          {...rest}
          render={props => (
            <LayoutPilotageSystem added_class={rest.added_class}  Component={Component} Layout={Layout} school_account={true} {...props} {...rest}/>
          )}
        />
      </>
    );
  }
  else{
    return (
      <>
        <Route
          {...rest}
          render={props => (
            <Component added_class={rest.added_class} {...props} />
          )}
        />
      </>
    );
  }
  
};

const AppEscortRoute = ({
  component: Component,
  layout: Layout,
  history,
  ...rest
}) => {
  var user = get("session_user");
  if(!user || (['pa', 'pr_pa', 'sc_ad', 'sc_as', "esc"].indexOf(user.account_type) < 0 && ["esc"].indexOf(user.account_type_2) < 0))
    return <Redirect to="/home/school" />;
  if(/([À-ú]|\s)/.test(user.profile.username)){
    var lev = get("level_groups");
    var current_language = get("current_language");
    clear();
    localforage.clear();
    if(global.ws_notification)
      global.ws_notification.close();
    set("level_groups", lev);
    set("current_language", current_language);
    return <Redirect to="/home/school" />;
  }
  if(Layout){
    return (
      <>
        <Route
          {...rest}
          render={props => (
            <LayoutEscortSystem  Component={Component} Layout={Layout} {...props} {...rest}/>
          )}
        />
      </>
    );
  }
  else{
    return (
      <>
        <Route
          {...rest}
          render={props => (
            <Component {...props} />
          )}
        />
      </>
    );
  }
  
};
const AppUnconnectedRoute = ({
  component: Component,
  layout: Layout,
  history,
  ...rest
}) => {
  if(Layout){
    return (
      <>
        <Route
          {...rest}
          render={props => (
            <LayoutUnconnectedSystem  Component={Component} Layout={Layout} {...props} {...rest}/>
          )}
        />
      </>
    );
  }
  else{
    return (
      <>
        <Route
          {...rest}
          render={props => (
            <Component {...props} />
          )}
        />
      </>
    );
  }
  
};

const AppHomeRoute = ({
  Component,
  history,
  ...rest
}) => {
  var user = get("session_user");
  if(user) {
    if((user.profile.is_program_system || user.profile.is_sanady_assistant) && !get('project'))
      return <Redirect to="/school/program/list" />;
    else if(user.profile.is_school_admin){
      if(get('school') && get('current_year'))
        return <Redirect to={`/school/${get('school').id}/${get('current_year').id}/classes`} />;
      else 
        return <Redirect to="/school/list" />;
    }
    else if(['esc'].indexOf(user.account_type) >= 0 || ['esc'].indexOf(user.account_type_2) >= 0)
      return <Redirect to={`/escort/transport`} />;
    else if(['pr','pr_pa'].indexOf(user.account_type) >= 0 && ((user.schools || user.profile && user.profile.schools || []).length > 0 && get('professor_subjects').length > 0 || user.profile && user.profile.had_sanady_school_from_last_year))
      return <Redirect to={`/professor/classes`} />;
    else if(['pa','pr_pa'].indexOf(user.account_type) >= 0)
      return <Redirect to={`/parents/dashboard`} />;
    else if(['ch'].indexOf(user.account_type) >= 0)
      return <Redirect to={`/child/dashboard`} />;
  }
  return (
    <>
      <Route
        {...rest}
        render={props => (
          <Component {...props} />
        )}
      />
    </>
  );
};

export default {
  AppUnconnectedRoute: withRouter(AppUnconnectedRoute),
  AppChildRoute: withRouter(AppChildRoute),
  AppParentRoute: withRouter(AppParentRoute),
  AppProfessorRoute: withRouter(AppProfessorRoute),
  AppSchoolRoute: withRouter(AppSchoolRoute),
  AppPilotageRoute: withRouter(AppPilotageRoute),
  AppEscortRoute: withRouter(AppEscortRoute),
  AppHomeRoute: withRouter(AppHomeRoute)
};
