import React, { Component } from "react";

import MediaQuery from "react-responsive";
import { get } from "../services/storage";
export default class ErrorComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div className="d-flex flex-column justify-content-center align-items-center" style={{height:'100vh'}}>
        <MediaQuery query="(min-width: 500px)">

            <img src={global.logo} alt="Logo" style={{width:'500px'}}/>
        </MediaQuery>
        <MediaQuery query="(max-width: 500px)">
            <img src={global.logo} alt="Logo" style={{width:'300px'}}/>
        </MediaQuery>
        <button type='button' className='btn btn-rounded btn-green mt-3' 
            onClick={() => {
                var user = get("session_user");
                if(user) {
                    if((user.profile.is_program_system || user.profile.is_sanady_assistant) && (!get('project') || get('project').is_parent_project))
                        this.props.history.push(`/school/program/list`); 
                    else if(user.profile.is_formator)
                        this.props.history.push(`/school/${get('school').id}/${get('current_year').id}/formations`);
                    else if(user.profile.is_school_admin){
                        if(get('school') && get('current_year'))
                            this.props.history.push(`/school/${get('school').id}/${get('current_year').id}/classes`);
                        else
                            this.props.history.push(`/school/list`); 
                    }
                    else if(['pr','pr_pa'].indexOf(user.account_type) >= 0 && ((user.schools || user.profile && user.profile.schools || []).length > 0 && get('professor_subjects').length > 0 || user.profile && user.profile.had_sanady_school_from_last_year))
                        this.props.history.push(`/professor/classes`);
                    else if(['pa','pr_pa'].indexOf(user.account_type) >= 0)
                        this.props.history.push(`/parents/dashboard`);
                    else if(['ch'].indexOf(user.account_type) >= 0)
                        this.props.history.push(`/child/dashboard`);
                }
                else
                    this.props.history.push(`/`);
            }}>
            Réessayer
        </button>
      </div>;
  }
}
